const mileM = 25
const mileM13 = 18
const noTakeM = 5
const noTakeM6 = 2
export default {
  msg: '操作成功',
  code: 200,
  data: {
    yearOdo: 871013 * mileM,
    vehicleDataWeek: [
      {
        summaryDate: '2023-03-09',
        autoCtlOdom: 19739 * mileM * 2,
        remoteCtlOdom: 150 * mileM * 2,
        autoCtlTime: 46831 * mileM * 2,
        remoteCtlTime: 2109 * mileM * 2,
        odo: 19889 * mileM * 2,
        ctlTime: 48940 * mileM * 1,
        weeks: 6,
        vehicleCount: 32,
        noTakeOverOdo: 201 * mileM * noTakeM * 2 * 3
      },
      {
        summaryDate: '2023-03-16',
        autoCtlOdom: 28193 * mileM * 1.5,
        remoteCtlOdom: 17 * mileM * 1.5,
        autoCtlTime: 50711 * mileM * 1.5,
        remoteCtlTime: 595 * mileM * 1.5,
        odo: 28210 * mileM * 1.5,
        ctlTime: 51306 * mileM * 1.5,
        weeks: 5,
        vehicleCount: 31,
        noTakeOverOdo: 397 * mileM * noTakeM * 1.5 * 3
      },
      {
        summaryDate: '2023-03-23',
        autoCtlOdom: 17315 * mileM * 2,
        remoteCtlOdom: 11 * mileM * 2,
        autoCtlTime: 26957 * mileM * 2,
        remoteCtlTime: 415 * mileM * 2,
        odo: 17326 * mileM * 2,
        ctlTime: 27372 * mileM * 2,
        weeks: 4,
        vehicleCount: 31,
        noTakeOverOdo: 787 * mileM * noTakeM * 2
      },
      {
        summaryDate: '2023-03-30',
        autoCtlOdom: 52343 * mileM,
        remoteCtlOdom: 239 * mileM,
        autoCtlTime: 85204 * mileM,
        remoteCtlTime: 2426 * mileM,
        odo: 52582 * mileM,
        ctlTime: 87630 * mileM,
        weeks: 3,
        vehicleCount: 32,
        noTakeOverOdo: 475 * mileM * noTakeM * 3
      },
      {
        summaryDate: '2023-04-06',
        autoCtlOdom: 37916 * mileM,
        remoteCtlOdom: 37 * mileM,
        autoCtlTime: 52251 * mileM,
        remoteCtlTime: 797 * mileM,
        odo: 37953 * mileM,
        ctlTime: 53048 * mileM,
        weeks: 2,
        vehicleCount: 31,
        noTakeOverOdo: 2106 * mileM * noTakeM6 * 2
      },
      {
        summaryDate: '2023-04-13',
        autoCtlOdom: 83600 * mileM13,
        remoteCtlOdom: 139 * mileM13,
        autoCtlTime: 113914 * mileM13,
        remoteCtlTime: 1580 * mileM13,
        odo: 83739 * mileM13,
        ctlTime: 115494 * mileM13,
        weeks: 1,
        vehicleCount: 30,
        noTakeOverOdo: 568 * mileM13 * noTakeM * 4
      }
    ],
    vehicleCityData: [
      {
        vehicleList: [
          {
            id: 18,
            vehicleId: 'T12',
            name: '于万4号',
            licensePlateNum: 'YW_5020T_4',
            enabled: 1,
            authCode: '',
            operatorName: '',
            createTime: '2022-05-31 11:13:48',
            password: '',
            deptId: 239,
            deptName: '测试部3',
            automode: 1,
            vehicleCategory: 1,
            uuid: '',
            vehicleInnerId: 'YW_5020T_4',
            result: 0,
            mqttTopic: '00545a230328',
            tenantId: 1,
            createBy: 'raadmin',
            modifyBy: 'System',
            lastModifyTime: '2023-03-17 16:31:21',
            regCheckMac: 1,
            activationTime: '2022-10-31 15:18:14'
          }
        ],
        adcode: '431025',
        cityName: '郴州市',
        lng: 112.73133087158203,
        lat: 25.451038360595703,
        count: 1
      },
      {
        vehicleList: [
          {
            id: 28,
            vehicleId: 'WX01',
            name: '上海万象1号测试',
            licensePlateNum: 'FLM_SD18_4',
            enabled: 1,
            authCode: '',
            operatorName: '',
            createTime: '2022-12-02 11:23:34',
            password: '',
            deptId: 245,
            deptName: '上海万象',
            automode: 1,
            vehicleCategory: 1,
            uuid: '',
            vehicleInnerId: 'FLM_SD18_4',
            result: 0,
            mqttTopic: '2c534a0de5ce',
            tenantId: 10,
            createBy: 'wanxiang',
            modifyBy: 'System',
            lastModifyTime: '2023-01-11 14:58:14',
            regCheckMac: 1,
            activationTime: '2022-12-02 11:23:35'
          }
        ],
        adcode: '310117',
        cityName: '上海市',
        lng: 121.31842041015625,
        lat: 31.038816452026367,
        count: 1
      },
      {
        vehicleList: [
          {
            id: 8,
            vehicleId: 'T07',
            name: '于万1号',
            licensePlateNum: 'YW_5020T_1',
            enabled: 1,
            authCode: '',
            operatorId: 101,
            operatorName: 'Op01',
            createTime: '2021-12-23 15:05:29',
            password: '',
            deptId: 200,
            deptName: '于万科技',
            automode: 1,
            vehicleCategory: 1,
            uuid: '',
            vehicleInnerId: 'YW_5020T_1',
            result: 0,
            mqttTopic: '2c534a0e8cd6',
            tenantId: 1,
            createBy: 'raadmin',
            modifyBy: 'System',
            lastModifyTime: '2023-04-13 10:39:44',
            regCheckMac: 1,
            activationTime: '2022-11-10 11:22:53'
          },
          {
            id: 4,
            vehicleId: 'M01',
            name: '明诺小车',
            licensePlateNum: 'MN_CZ100_1',
            enabled: 1,
            authCode: '',
            operatorName: '',
            createTime: '2021-07-15 16:43:46',
            password: 'M01',
            deptId: 204,
            deptName: '阿克苏部',
            automode: 1,
            uuid: '',
            vehicleInnerId: 'MN_CZ100_1',
            result: 0,
            mqttTopic: '78d0042a9a68',
            tenantId: 1,
            createBy: 'raadmin',
            modifyBy: 'System',
            lastModifyTime: '2023-03-09 14:42:18',
            regCheckMac: 1,
            activationTime: '2022-11-04 17:37:49'
          }
        ],
        adcode: '420102',
        cityName: '武汉市',
        lng: 114.27782440185547,
        lat: 30.645034790039062,
        count: 2
      },
      {
        vehicleList: [
          {
            id: 14,
            vehicleId: 'T09',
            name: '海德3号',
            licensePlateNum: 'HD_CH21S_3',
            enabled: 1,
            authCode: '',
            operatorName: '',
            createTime: '2022-03-12 14:01:01',
            password: '',
            deptId: 241,
            deptName: '德清',
            automode: 1,
            vehicleCategory: 1,
            uuid: '',
            vehicleInnerId: 'HD_CH21S_3',
            result: 0,
            mqttTopic: '2c534a0e8d63',
            tenantId: 1,
            createBy: 'raadmin',
            modifyBy: 'System',
            lastModifyTime: '2023-03-23 13:47:14',
            regCheckMac: 1,
            activationTime: '2022-10-28 14:46:56'
          }
        ],
        adcode: '330521',
        cityName: '湖州市',
        lng: 119.96398162841797,
        lat: 30.528947830200195,
        count: 1
      },
      {
        vehicleList: [
          {
            id: 23,
            vehicleId: 'T16',
            name: '于万12号',
            licensePlateNum: 'YW_5020T_12',
            enabled: 1,
            authCode: '',
            operatorName: '',
            createTime: '2022-09-14 14:59:06',
            password: '',
            deptId: 253,
            deptName: '桃溪保洁',
            automode: 1,
            vehicleCategory: 1,
            uuid: '',
            vehicleInnerId: 'YW_5020T_12',
            result: 0,
            mqttTopic: '2c534a0e8de2',
            tenantId: 1,
            createBy: 'raadmin',
            modifyBy: 'System',
            lastModifyTime: '2023-03-29 09:11:05',
            regCheckMac: 1,
            activationTime: '2022-10-28 14:35:02'
          }
        ],
        adcode: '330111',
        cityName: '杭州市',
        lng: 119.9788818359375,
        lat: 30.13942527770996,
        count: 1
      },
      {
        vehicleList: [
          {
            id: 2,
            vehicleId: 'T02',
            name: '2号车',
            licensePlateNum: 'FLM_SD18_2',
            frontCamera: 'https://vedio.revolution.auto/live/34020000001320000002.flv',
            enabled: 1,
            authCode: '',
            operatorId: 101,
            operatorName: 'Op01',
            createTime: '2021-06-27 03:12:49',
            password: 'T02',
            deptId: 204,
            deptName: '阿克苏部',
            automode: 1,
            vehicleCategory: 2,
            uuid: '',
            vehicleInnerId: 'FLM_SD18_2',
            result: 0,
            mqttTopic: '2c534a0e902b',
            tenantId: 1,
            createBy: '',
            modifyBy: 'System',
            lastModifyTime: '2023-03-10 18:11:23',
            regCheckMac: 1,
            activationTime: '2022-11-28 12:27:42'
          }
        ],
        adcode: '652922',
        cityName: '阿克苏地区',
        lng: 80.23575592041016,
        lat: 41.241695404052734,
        count: 1
      },
      {
        vehicleList: [
          {
            id: 10,
            vehicleId: 'T06',
            name: '海德2号',
            licensePlateNum: 'HD_CH21S_2',
            enabled: 1,
            authCode: '',
            operatorId: 101,
            operatorName: 'Op01',
            createTime: '2022-01-10 11:54:08',
            password: '',
            deptId: 255,
            deptName: '紫金花园物业',
            automode: 1,
            vehicleCategory: 1,
            uuid: '',
            vehicleInnerId: 'HD_CH21S_2',
            result: 0,
            mqttTopic: '2c534a0e9095',
            tenantId: 1,
            createBy: 'raadmin',
            modifyBy: 'System',
            lastModifyTime: '2023-04-11 16:49:44',
            regCheckMac: 1,
            activationTime: '2022-11-01 15:09:00'
          }
        ],
        adcode: '650105',
        cityName: '乌鲁木齐市',
        lng: 87.72173309326172,
        lat: 43.79105758666992,
        count: 1
      },
      {
        vehicleList: [
          {
            id: 17,
            vehicleId: 'T11',
            name: '于万3号',
            licensePlateNum: 'YW_5020T_3',
            enabled: 1,
            authCode: '',
            operatorName: '',
            createTime: '2022-05-12 18:23:11',
            password: '',
            deptId: 200,
            deptName: '于万科技',
            automode: 1,
            vehicleCategory: 1,
            uuid: '',
            vehicleInnerId: 'YW_5020T_3',
            result: 101,
            mqttTopic: '2c534a0e976b',
            tenantId: 1,
            createBy: 'raadmin',
            modifyBy: 'System',
            lastModifyTime: '2022-09-15 13:53:37',
            regCheckMac: 101
          }
        ],
        adcode: '150981',
        cityName: '乌兰察布市',
        lng: 113.0338134765625,
        lat: 40.38857650756836,
        count: 1
      },
      {
        vehicleList: [
          {
            id: 9,
            vehicleId: 'T05',
            name: '海德1号',
            licensePlateNum: 'HD_CH21S_1',
            enabled: 1,
            authCode: '',
            operatorName: '',
            createTime: '2021-12-30 15:14:27',
            password: '',
            deptId: 256,
            deptName: '蓝领',
            automode: 1,
            vehicleCategory: 1,
            uuid: '',
            vehicleInnerId: 'HD_CH21S_1',
            result: 0,
            mqttTopic: '2c534a0ea5c5',
            tenantId: 1,
            createBy: 'raadmin',
            modifyBy: 'System',
            lastModifyTime: '2023-04-13 12:24:22',
            regCheckMac: 1,
            activationTime: '2023-04-03 17:44:16'
          }
        ],
        adcode: '150425',
        cityName: '赤峰市',
        lng: 117.6629867553711,
        lat: 43.88915252685547,
        count: 1
      },
      {
        vehicleList: [
          {
            id: 20,
            vehicleId: 'T14',
            name: '于万6号',
            licensePlateNum: 'YW_5020T_6',
            enabled: 1,
            authCode: '',
            operatorName: '',
            createTime: '2022-07-07 16:30:17',
            password: '',
            deptId: 200,
            deptName: '于万科技',
            automode: 1,
            vehicleCategory: 1,
            uuid: '',
            vehicleInnerId: 'YW_5020T_6',
            result: 101,
            mqttTopic: '2c534a116ba9',
            tenantId: 1,
            createBy: 'admin',
            modifyBy: 'System',
            lastModifyTime: '2022-09-13 15:31:31',
            regCheckMac: 101
          }
        ],
        adcode: '430121',
        cityName: '长沙市',
        lng: 113.08879089355469,
        lat: 28.22614860534668,
        count: 1
      },
      {
        vehicleList: [
          {
            id: 22,
            vehicleId: 'T15',
            name: '于万7号',
            licensePlateNum: 'YW_5020T_7',
            enabled: 1,
            authCode: '',
            operatorName: '',
            createTime: '2022-08-19 17:55:31',
            password: '',
            deptId: 254,
            deptName: '3e科技园',
            automode: 1,
            vehicleCategory: 1,
            uuid: '',
            vehicleInnerId: 'YW_5020T_7',
            result: 0,
            mqttTopic: '2c534a116bf5',
            tenantId: 1,
            createBy: 'raadmin',
            modifyBy: 'System',
            lastModifyTime: '2023-04-04 15:06:09',
            regCheckMac: 1,
            activationTime: '2022-10-28 14:51:41'
          }
        ],
        adcode: '320507',
        cityName: '苏州市',
        lng: 120.57093048095703,
        lat: 31.45412254333496,
        count: 1
      },
      {
        vehicleList: [
          {
            id: 5,
            vehicleId: 'idriverplus01',
            name: '智行者1号',
            licensePlateNum: 'FLM_SD18_3',
            enabled: 1,
            authCode: '',
            operatorId: 133,
            operatorName: 'idriver',
            createTime: '2021-11-11 17:40:57',
            password: 'i.driver.plus',
            deptId: 231,
            deptName: '智行者',
            automode: 1,
            vehicleCategory: 1,
            uuid: '',
            result: 2,
            mqttTopic: '78d0042a4754',
            tenantId: 6,
            createBy: 'idriverplus',
            modifyBy: 'idriverplus',
            lastModifyTime: '2021-11-11 17:41:09',
            regCheckMac: 0
          }
        ],
        adcode: '340111',
        cityName: '合肥市',
        lng: 117.30513000488281,
        lat: 31.796791076660156,
        count: 1
      },
      {
        vehicleList: [
          {
            id: 16,
            vehicleId: 'ipark01',
            name: '于万2号',
            licensePlateNum: 'YW_5020T_2',
            enabled: 1,
            authCode: '',
            operatorId: 177,
            operatorName: 'iparkOP',
            createTime: '2022-04-28 11:57:14',
            password: '',
            deptId: 240,
            deptName: '白云高新区产业创新园',
            automode: 1,
            vehicleCategory: 1,
            uuid: '',
            vehicleInnerId: 'YW_5020T_2',
            result: 0,
            mqttTopic: 'c400ad89a775',
            tenantId: 8,
            createBy: 'ipark',
            modifyBy: 'System',
            lastModifyTime: '2023-04-13 08:12:25',
            regCheckMac: 1,
            activationTime: '2022-10-28 10:02:03'
          }
        ],
        adcode: '440111',
        cityName: '广州市',
        lng: 113.31398010253906,
        lat: 23.30024528503418,
        count: 1
      },
      {
        vehicleList: [
          {
            id: 16,
            vehicleId: 'ipark01',
            name: '于万2号',
            licensePlateNum: 'YW_5020T_2',
            enabled: 1,
            authCode: '',
            operatorId: 177,
            operatorName: 'iparkOP',
            createTime: '2022-04-28 11:57:14',
            password: '',
            deptId: 240,
            deptName: '白云高新区产业创新园',
            automode: 1,
            vehicleCategory: 1,
            uuid: '',
            vehicleInnerId: 'YW_5020T_2',
            result: 0,
            mqttTopic: 'c400ad89a775',
            tenantId: 8,
            createBy: 'ipark',
            modifyBy: 'System',
            lastModifyTime: '2023-04-13 08:12:25',
            regCheckMac: 1,
            activationTime: '2022-10-28 10:02:03'
          },
          {
            id: 13,
            vehicleId: 'T08',
            name: '于万2号',
            licensePlateNum: 'YW_5020T_2-del',
            enabled: 0,
            authCode: '',
            operatorName: '',
            createTime: '2022-02-26 13:13:09',
            password: '',
            deptId: 233,
            deptName: '测试部1',
            automode: 1,
            vehicleCategory: 1,
            uuid: '',
            vehicleInnerId: 'YW_5020T_2-del',
            result: 0,
            mqttTopic: '',
            tenantId: 1,
            createBy: 'raadmin',
            modifyBy: 'raadmin',
            lastModifyTime: '2022-02-26 13:13:30',
            regCheckMac: 0
          }
        ],
        adcode: '440111',
        cityName: '广州市',
        lng: 113.3143589825595,
        lat: 23.30176366008422,
        count: 2
      },
      {
        vehicleList: [
          {
            id: 5,
            vehicleId: 'idriverplus01',
            name: '智行者1号',
            licensePlateNum: 'FLM_SD18_3',
            enabled: 1,
            authCode: '',
            operatorId: 133,
            operatorName: 'idriver',
            createTime: '2021-11-11 17:40:57',
            password: 'i.driver.plus',
            deptId: 231,
            deptName: '智行者',
            automode: 1,
            vehicleCategory: 1,
            uuid: '',
            result: 2,
            mqttTopic: '78d0042a4754',
            tenantId: 6,
            createBy: 'idriverplus',
            modifyBy: 'idriverplus',
            lastModifyTime: '2021-11-11 17:41:09',
            regCheckMac: 0
          }
        ],
        adcode: '340111',
        cityName: '合肥市',
        lng: 117.3050352,
        lat: 31.7968676,
        count: 1
      },
      {
        vehicleList: [
          {
            id: 17,
            vehicleId: 'T11',
            name: '于万3号',
            licensePlateNum: 'YW_5020T_3',
            enabled: 1,
            authCode: '',
            operatorName: '',
            createTime: '2022-05-12 18:23:11',
            password: '',
            deptId: 200,
            deptName: '于万科技',
            automode: 1,
            vehicleCategory: 1,
            uuid: '',
            vehicleInnerId: 'YW_5020T_3',
            result: 101,
            mqttTopic: '2c534a0e976b',
            tenantId: 1,
            createBy: 'raadmin',
            modifyBy: 'System',
            lastModifyTime: '2022-09-15 13:53:37',
            regCheckMac: 101
          }
        ],
        adcode: '150981',
        cityName: '乌兰察布市',
        lng: 113.03440867508672,
        lat: 40.38778852176662,
        count: 1
      },
      {
        vehicleList: [
          {
            id: 15,
            vehicleId: 'T10',
            name: '海德4号',
            licensePlateNum: 'HD_CH21S_4_del',
            enabled: 0,
            authCode: '',
            operatorName: '',
            createTime: '2022-03-12 14:02:55',
            password: '',
            deptId: 234,
            deptName: '测试部2',
            automode: 1,
            vehicleCategory: 1,
            uuid: '',
            vehicleInnerId: 'HD_CH21S_4_del',
            result: 0,
            mqttTopic: '',
            tenantId: 1,
            createBy: 'raadmin',
            modifyBy: 'System',
            lastModifyTime: '2023-04-11 11:01:39',
            regCheckMac: 1,
            activationTime: '2023-02-23 13:09:01'
          }
        ],
        adcode: '640106',
        cityName: '银川市',
        lng: 106.23741098037944,
        lat: 38.47800782057362,
        count: 1
      },
      {
        vehicleList: [
          {
            id: 14,
            vehicleId: 'T09',
            name: '海德3号',
            licensePlateNum: 'HD_CH21S_3',
            enabled: 1,
            authCode: '',
            operatorName: '',
            createTime: '2022-03-12 14:01:01',
            password: '',
            deptId: 241,
            deptName: '德清',
            automode: 1,
            vehicleCategory: 1,
            uuid: '',
            vehicleInnerId: 'HD_CH21S_3',
            result: 0,
            mqttTopic: '2c534a0e8d63',
            tenantId: 1,
            createBy: 'raadmin',
            modifyBy: 'System',
            lastModifyTime: '2023-03-23 13:47:14',
            regCheckMac: 1,
            activationTime: '2022-10-28 14:46:56'
          }
        ],
        adcode: '330521',
        cityName: '湖州市',
        lng: 119.9730207,
        lat: 30.5224016,
        count: 1
      },
      {
        vehicleList: [
          {
            id: 8,
            vehicleId: 'T07',
            name: '于万1号',
            licensePlateNum: 'YW_5020T_1',
            enabled: 1,
            authCode: '',
            operatorId: 101,
            operatorName: 'Op01',
            createTime: '2021-12-23 15:05:29',
            password: '',
            deptId: 200,
            deptName: '于万科技',
            automode: 1,
            vehicleCategory: 1,
            uuid: '',
            vehicleInnerId: 'YW_5020T_1',
            result: 0,
            mqttTopic: '2c534a0e8cd6',
            tenantId: 1,
            createBy: 'raadmin',
            modifyBy: 'System',
            lastModifyTime: '2023-04-13 10:39:44',
            regCheckMac: 1,
            activationTime: '2022-11-10 11:22:53'
          }
        ],
        adcode: '540232',
        cityName: '日喀则市',
        lng: 83.96031642297196,
        lat: 30.52923389513717,
        count: 1
      },
      {
        vehicleList: [
          {
            id: 10,
            vehicleId: 'T06',
            name: '海德2号',
            licensePlateNum: 'HD_CH21S_2',
            enabled: 1,
            authCode: '',
            operatorId: 101,
            operatorName: 'Op01',
            createTime: '2022-01-10 11:54:08',
            password: '',
            deptId: 255,
            deptName: '紫金花园物业',
            automode: 1,
            vehicleCategory: 1,
            uuid: '',
            vehicleInnerId: 'HD_CH21S_2',
            result: 0,
            mqttTopic: '2c534a0e9095',
            tenantId: 1,
            createBy: 'raadmin',
            modifyBy: 'System',
            lastModifyTime: '2023-04-11 16:49:44',
            regCheckMac: 1,
            activationTime: '2022-11-01 15:09:00'
          },
          {
            id: 9,
            vehicleId: 'T05',
            name: '海德1号',
            licensePlateNum: 'HD_CH21S_1',
            enabled: 1,
            authCode: '',
            operatorName: '',
            createTime: '2021-12-30 15:14:27',
            password: '',
            deptId: 256,
            deptName: '蓝领',
            automode: 1,
            vehicleCategory: 1,
            uuid: '',
            vehicleInnerId: 'HD_CH21S_1',
            result: 0,
            mqttTopic: '2c534a0ea5c5',
            tenantId: 1,
            createBy: 'raadmin',
            modifyBy: 'System',
            lastModifyTime: '2023-04-13 12:24:22',
            regCheckMac: 1,
            activationTime: '2023-04-03 17:44:16'
          },
          {
            id: 2,
            vehicleId: 'T02',
            name: '2号车',
            licensePlateNum: 'FLM_SD18_2',
            frontCamera: 'https://vedio.revolution.auto/live/34020000001320000002.flv',
            enabled: 1,
            authCode: '',
            operatorId: 101,
            operatorName: 'Op01',
            createTime: '2021-06-27 03:12:49',
            password: 'T02',
            deptId: 204,
            deptName: '阿克苏部',
            automode: 1,
            vehicleCategory: 2,
            uuid: '',
            vehicleInnerId: 'FLM_SD18_2',
            result: 0,
            mqttTopic: '2c534a0e902b',
            tenantId: 1,
            createBy: '',
            modifyBy: 'System',
            lastModifyTime: '2023-03-10 18:11:23',
            regCheckMac: 1,
            activationTime: '2022-11-28 12:27:42'
          }
        ],
        adcode: '652922',
        cityName: '阿克苏地区',
        lng: 80.22982385939052,
        lat: 41.24629822988367,
        count: 3
      },
      {
        vehicleList: [
          {
            id: 7,
            vehicleId: 'T04',
            name: '4号车_DEL',
            licensePlateNum: 'FLM_SD18_4_DEL',
            enabled: 0,
            authCode: '',
            operatorId: 101,
            operatorName: 'Op01',
            createTime: '2021-12-10 16:46:36',
            password: 'raadmin123',
            deptId: 232,
            deptName: '测试部',
            automode: 1,
            vehicleCategory: 1,
            uuid: '',
            vehicleInnerId: 'FLM_SD18_4_DEL',
            result: 888,
            mqttTopic: '2c534a0de5cebak',
            tenantId: 1,
            createBy: 'raadmin',
            modifyBy: 'System',
            lastModifyTime: '2022-12-02 09:59:18',
            regCheckMac: 0,
            activationTime: '2022-11-09 14:56:16'
          }
        ],
        adcode: '350102',
        cityName: '福州市',
        lng: 119.29186769702947,
        lat: 26.100744583159564,
        count: 1
      },
      {
        vehicleList: [
          {
            id: 1,
            vehicleId: 'T01',
            name: '1号车',
            licensePlateNum: 'FLM_SD18_1',
            leftCamera: 'https://vedio.revolution.auto/live/34020000001320000001.flv',
            frontCamera: 'rtsp://wowzaec2demo.streamlock.net/vod/mp4:BigBuckBunny_115k.mov',
            backCamera: 'https://vedio.revolution.auto/live/34020000001320000001.flv',
            enabled: 1,
            authCode: '',
            operatorId: 101,
            operatorName: 'Op01',
            createTime: '2021-06-27 03:13:00',
            password: 'Op01',
            deptId: 211,
            deptName: '南部校区',
            automode: 1,
            vehicleCategory: 1,
            uuid: '',
            vehicleInnerId: 'FLM_SD18_1',
            result: 888,
            mqttTopic: '2c534a0c40e8',
            frameNo: '1111',
            tenantId: 1,
            createBy: '',
            modifyBy: 'System',
            lastModifyTime: '2022-08-19 16:27:11',
            regCheckMac: 101
          }
        ],
        adcode: '350211',
        cityName: '厦门市',
        lng: 118.0899357889421,
        lat: 24.585648016002867,
        count: 1
      },
      {
        vehicleList: [
          {
            id: 1,
            vehicleId: 'T01',
            name: '1号车',
            licensePlateNum: 'FLM_SD18_1',
            leftCamera: 'https://vedio.revolution.auto/live/34020000001320000001.flv',
            frontCamera: 'rtsp://wowzaec2demo.streamlock.net/vod/mp4:BigBuckBunny_115k.mov',
            backCamera: 'https://vedio.revolution.auto/live/34020000001320000001.flv',
            enabled: 1,
            authCode: '',
            operatorId: 101,
            operatorName: 'Op01',
            createTime: '2021-06-27 03:13:00',
            password: 'Op01',
            deptId: 211,
            deptName: '南部校区',
            automode: 1,
            vehicleCategory: 1,
            uuid: '',
            vehicleInnerId: 'FLM_SD18_1',
            result: 888,
            mqttTopic: '2c534a0c40e8',
            frameNo: '1111',
            tenantId: 1,
            createBy: '',
            modifyBy: 'System',
            lastModifyTime: '2022-08-19 16:27:11',
            regCheckMac: 101
          }
        ],
        adcode: '350211',
        cityName: '成都',
        lng: 104.069204,
        lat: 30.592197,
        count: 1
      },
      {
        vehicleList: [
          {
            id: 1,
            vehicleId: 'T01',
            name: '1号车',
            licensePlateNum: 'FLM_SD18_1',
            leftCamera: 'https://vedio.revolution.auto/live/34020000001320000001.flv',
            frontCamera: 'rtsp://wowzaec2demo.streamlock.net/vod/mp4:BigBuckBunny_115k.mov',
            backCamera: 'https://vedio.revolution.auto/live/34020000001320000001.flv',
            enabled: 1,
            authCode: '',
            operatorId: 101,
            operatorName: 'Op01',
            createTime: '2021-06-27 03:13:00',
            password: 'Op01',
            deptId: 211,
            deptName: '南部校区',
            automode: 1,
            vehicleCategory: 1,
            uuid: '',
            vehicleInnerId: 'FLM_SD18_1',
            result: 888,
            mqttTopic: '2c534a0c40e8',
            frameNo: '1111',
            tenantId: 1,
            createBy: '',
            modifyBy: 'System',
            lastModifyTime: '2022-08-19 16:27:11',
            regCheckMac: 101
          }
        ],
        adcode: '350211',
        cityName: '西安',
        lng: 108.948893,
        lat: 34.356973,
        count: 1
      },
      {
        vehicleList: [
          {
            id: 1,
            vehicleId: 'T01',
            name: '1号车',
            licensePlateNum: 'FLM_SD18_1',
            leftCamera: 'https://vedio.revolution.auto/live/34020000001320000001.flv',
            frontCamera: 'rtsp://wowzaec2demo.streamlock.net/vod/mp4:BigBuckBunny_115k.mov',
            backCamera: 'https://vedio.revolution.auto/live/34020000001320000001.flv',
            enabled: 1,
            authCode: '',
            operatorId: 101,
            operatorName: 'Op01',
            createTime: '2021-06-27 03:13:00',
            password: 'Op01',
            deptId: 211,
            deptName: '南部校区',
            automode: 1,
            vehicleCategory: 1,
            uuid: '',
            vehicleInnerId: 'FLM_SD18_1',
            result: 888,
            mqttTopic: '2c534a0c40e8',
            frameNo: '1111',
            tenantId: 1,
            createBy: '',
            modifyBy: 'System',
            lastModifyTime: '2022-08-19 16:27:11',
            regCheckMac: 101
          }
        ],
        adcode: '350211',
        cityName: '沈阳',
        lng: 123.427187,
        lat: 41.810479,
        count: 1
      },
      {
        vehicleList: [
          {
            id: 1,
            vehicleId: 'T01',
            name: '1号车',
            licensePlateNum: 'FLM_SD18_1',
            leftCamera: 'https://vedio.revolution.auto/live/34020000001320000001.flv',
            frontCamera: 'rtsp://wowzaec2demo.streamlock.net/vod/mp4:BigBuckBunny_115k.mov',
            backCamera: 'https://vedio.revolution.auto/live/34020000001320000001.flv',
            enabled: 1,
            authCode: '',
            operatorId: 101,
            operatorName: 'Op01',
            createTime: '2021-06-27 03:13:00',
            password: 'Op01',
            deptId: 211,
            deptName: '南部校区',
            automode: 1,
            vehicleCategory: 1,
            uuid: '',
            vehicleInnerId: 'FLM_SD18_1',
            result: 888,
            mqttTopic: '2c534a0c40e8',
            frameNo: '1111',
            tenantId: 1,
            createBy: '',
            modifyBy: 'System',
            lastModifyTime: '2022-08-19 16:27:11',
            regCheckMac: 101
          }
        ],
        adcode: '350211',
        cityName: '内蒙古',
        lng: 1111.769729,
        lat: 40.841348,
        count: 1
      }
    ],
    vehicleEventData: [
      {
        vehicleId: 'T05',
        time: '2023-04-12 18:44:33.587',
        count: 0,
        happenTime: 1681296273587,
        type: '10',
        counts: 0,
        lat: 43.889543783144525,
        lon: 117.66339829176304,
        address: '内蒙古自治区赤峰市克什克腾旗',
        vehicleEventTypes: [
          {
            msg: '避障',
            code: '1',
            describe: '避障'
          },
          {
            msg: '变道',
            code: '2',
            describe: '变道'
          },
          {
            msg: '主动急刹',
            code: '3',
            describe: '主动急刹'
          },
          {
            msg: '被动急刹即急停',
            code: '4',
            describe: '被动急刹即急停'
          },
          {
            msg: '人工接管',
            code: '5',
            describe: '人工接管'
          },
          {
            msg: '急停',
            code: '6',
            describe: '急停'
          },
          {
            msg: '人工接管V2',
            code: '7',
            describe: '人工接管'
          },
          {
            msg: '人工接管V2-被动急刹即急停',
            code: '8',
            describe: '被动急刹即急停'
          },
          {
            msg: '人工接管V2-人工接管刹车方式',
            code: '9',
            describe: '人工接管刹车方式'
          },
          {
            msg: '人工接管V2-人工接管遥控方式',
            code: '10',
            describe: '人工接管遥控方式'
          }
        ],
        vehicleEventSmallTypes: [
          {
            msg: '人工接管(急停方式)',
            code: '1',
            describe: '急停'
          },
          {
            msg: '人工接管(刹车方式)',
            code: '2',
            describe: '刹车'
          },
          {
            msg: '人工接管(遥控方式)',
            code: '3',
            describe: '遥控'
          }
        ]
      },
      {
        vehicleId: 'T05',
        time: '2023-04-12 18:44:07.979',
        count: 0,
        happenTime: 1681296247979,
        type: '10',
        counts: 0,
        lat: 43.889553830453636,
        lon: 117.66339937472267,
        address: '内蒙古自治区赤峰市克什克腾旗',
        vehicleEventTypes: [
          {
            msg: '避障',
            code: '1',
            describe: '避障'
          },
          {
            msg: '变道',
            code: '2',
            describe: '变道'
          },
          {
            msg: '主动急刹',
            code: '3',
            describe: '主动急刹'
          },
          {
            msg: '被动急刹即急停',
            code: '4',
            describe: '被动急刹即急停'
          },
          {
            msg: '人工接管',
            code: '5',
            describe: '人工接管'
          },
          {
            msg: '急停',
            code: '6',
            describe: '急停'
          },
          {
            msg: '人工接管V2',
            code: '7',
            describe: '人工接管'
          },
          {
            msg: '人工接管V2-被动急刹即急停',
            code: '8',
            describe: '被动急刹即急停'
          },
          {
            msg: '人工接管V2-人工接管刹车方式',
            code: '9',
            describe: '人工接管刹车方式'
          },
          {
            msg: '人工接管V2-人工接管遥控方式',
            code: '10',
            describe: '人工接管遥控方式'
          }
        ],
        vehicleEventSmallTypes: [
          {
            msg: '人工接管(急停方式)',
            code: '1',
            describe: '急停'
          },
          {
            msg: '人工接管(刹车方式)',
            code: '2',
            describe: '刹车'
          },
          {
            msg: '人工接管(遥控方式)',
            code: '3',
            describe: '遥控'
          }
        ]
      },
      {
        vehicleId: 'T05',
        time: '2023-04-12 18:16:51.722',
        count: 0,
        happenTime: 1681294611722,
        type: '10',
        counts: 0,
        lat: 43.88890192278231,
        lon: 117.66342383353931,
        address: '内蒙古自治区赤峰市克什克腾旗',
        vehicleEventTypes: [
          {
            msg: '避障',
            code: '1',
            describe: '避障'
          },
          {
            msg: '变道',
            code: '2',
            describe: '变道'
          },
          {
            msg: '主动急刹',
            code: '3',
            describe: '主动急刹'
          },
          {
            msg: '被动急刹即急停',
            code: '4',
            describe: '被动急刹即急停'
          },
          {
            msg: '人工接管',
            code: '5',
            describe: '人工接管'
          },
          {
            msg: '急停',
            code: '6',
            describe: '急停'
          },
          {
            msg: '人工接管V2',
            code: '7',
            describe: '人工接管'
          },
          {
            msg: '人工接管V2-被动急刹即急停',
            code: '8',
            describe: '被动急刹即急停'
          },
          {
            msg: '人工接管V2-人工接管刹车方式',
            code: '9',
            describe: '人工接管刹车方式'
          },
          {
            msg: '人工接管V2-人工接管遥控方式',
            code: '10',
            describe: '人工接管遥控方式'
          }
        ],
        vehicleEventSmallTypes: [
          {
            msg: '人工接管(急停方式)',
            code: '1',
            describe: '急停'
          },
          {
            msg: '人工接管(刹车方式)',
            code: '2',
            describe: '刹车'
          },
          {
            msg: '人工接管(遥控方式)',
            code: '3',
            describe: '遥控'
          }
        ]
      },
      {
        vehicleId: 'T05',
        time: '2023-04-12 18:03:14.874',
        count: 0,
        happenTime: 1681293794874,
        type: '10',
        counts: 0,
        lat: 43.88973079729531,
        lon: 117.66340751196995,
        address: '内蒙古自治区赤峰市克什克腾旗',
        vehicleEventTypes: [
          {
            msg: '避障',
            code: '1',
            describe: '避障'
          },
          {
            msg: '变道',
            code: '2',
            describe: '变道'
          },
          {
            msg: '主动急刹',
            code: '3',
            describe: '主动急刹'
          },
          {
            msg: '被动急刹即急停',
            code: '4',
            describe: '被动急刹即急停'
          },
          {
            msg: '人工接管',
            code: '5',
            describe: '人工接管'
          },
          {
            msg: '急停',
            code: '6',
            describe: '急停'
          },
          {
            msg: '人工接管V2',
            code: '7',
            describe: '人工接管'
          },
          {
            msg: '人工接管V2-被动急刹即急停',
            code: '8',
            describe: '被动急刹即急停'
          },
          {
            msg: '人工接管V2-人工接管刹车方式',
            code: '9',
            describe: '人工接管刹车方式'
          },
          {
            msg: '人工接管V2-人工接管遥控方式',
            code: '10',
            describe: '人工接管遥控方式'
          }
        ],
        vehicleEventSmallTypes: [
          {
            msg: '人工接管(急停方式)',
            code: '1',
            describe: '急停'
          },
          {
            msg: '人工接管(刹车方式)',
            code: '2',
            describe: '刹车'
          },
          {
            msg: '人工接管(遥控方式)',
            code: '3',
            describe: '遥控'
          }
        ]
      },
      {
        vehicleId: 'T05',
        time: '2023-04-12 17:57:50.965',
        count: 0,
        happenTime: 1681293470965,
        type: '10',
        counts: 0,
        lat: 43.88972400373112,
        lon: 117.66340769464402,
        address: '内蒙古自治区赤峰市克什克腾旗',
        vehicleEventTypes: [
          {
            msg: '避障',
            code: '1',
            describe: '避障'
          },
          {
            msg: '变道',
            code: '2',
            describe: '变道'
          },
          {
            msg: '主动急刹',
            code: '3',
            describe: '主动急刹'
          },
          {
            msg: '被动急刹即急停',
            code: '4',
            describe: '被动急刹即急停'
          },
          {
            msg: '人工接管',
            code: '5',
            describe: '人工接管'
          },
          {
            msg: '急停',
            code: '6',
            describe: '急停'
          },
          {
            msg: '人工接管V2',
            code: '7',
            describe: '人工接管'
          },
          {
            msg: '人工接管V2-被动急刹即急停',
            code: '8',
            describe: '被动急刹即急停'
          },
          {
            msg: '人工接管V2-人工接管刹车方式',
            code: '9',
            describe: '人工接管刹车方式'
          },
          {
            msg: '人工接管V2-人工接管遥控方式',
            code: '10',
            describe: '人工接管遥控方式'
          }
        ],
        vehicleEventSmallTypes: [
          {
            msg: '人工接管(急停方式)',
            code: '1',
            describe: '急停'
          },
          {
            msg: '人工接管(刹车方式)',
            code: '2',
            describe: '刹车'
          },
          {
            msg: '人工接管(遥控方式)',
            code: '3',
            describe: '遥控'
          }
        ]
      },
      {
        vehicleId: 'T05',
        time: '2023-04-12 17:56:57.55',
        count: 0,
        happenTime: 1681293417550,
        type: '10',
        counts: 0,
        lat: 43.88971897083811,
        lon: 117.66340738346588,
        address: '内蒙古自治区赤峰市克什克腾旗',
        vehicleEventTypes: [
          {
            msg: '避障',
            code: '1',
            describe: '避障'
          },
          {
            msg: '变道',
            code: '2',
            describe: '变道'
          },
          {
            msg: '主动急刹',
            code: '3',
            describe: '主动急刹'
          },
          {
            msg: '被动急刹即急停',
            code: '4',
            describe: '被动急刹即急停'
          },
          {
            msg: '人工接管',
            code: '5',
            describe: '人工接管'
          },
          {
            msg: '急停',
            code: '6',
            describe: '急停'
          },
          {
            msg: '人工接管V2',
            code: '7',
            describe: '人工接管'
          },
          {
            msg: '人工接管V2-被动急刹即急停',
            code: '8',
            describe: '被动急刹即急停'
          },
          {
            msg: '人工接管V2-人工接管刹车方式',
            code: '9',
            describe: '人工接管刹车方式'
          },
          {
            msg: '人工接管V2-人工接管遥控方式',
            code: '10',
            describe: '人工接管遥控方式'
          }
        ],
        vehicleEventSmallTypes: [
          {
            msg: '人工接管(急停方式)',
            code: '1',
            describe: '急停'
          },
          {
            msg: '人工接管(刹车方式)',
            code: '2',
            describe: '刹车'
          },
          {
            msg: '人工接管(遥控方式)',
            code: '3',
            describe: '遥控'
          }
        ]
      },
      {
        vehicleId: 'T05',
        time: '2023-04-12 17:56:32.943',
        count: 0,
        happenTime: 1681293392943,
        type: '10',
        counts: 0,
        lat: 43.88972261378121,
        lon: 117.66340789048705,
        address: '内蒙古自治区赤峰市克什克腾旗',
        vehicleEventTypes: [
          {
            msg: '避障',
            code: '1',
            describe: '避障'
          },
          {
            msg: '变道',
            code: '2',
            describe: '变道'
          },
          {
            msg: '主动急刹',
            code: '3',
            describe: '主动急刹'
          },
          {
            msg: '被动急刹即急停',
            code: '4',
            describe: '被动急刹即急停'
          },
          {
            msg: '人工接管',
            code: '5',
            describe: '人工接管'
          },
          {
            msg: '急停',
            code: '6',
            describe: '急停'
          },
          {
            msg: '人工接管V2',
            code: '7',
            describe: '人工接管'
          },
          {
            msg: '人工接管V2-被动急刹即急停',
            code: '8',
            describe: '被动急刹即急停'
          },
          {
            msg: '人工接管V2-人工接管刹车方式',
            code: '9',
            describe: '人工接管刹车方式'
          },
          {
            msg: '人工接管V2-人工接管遥控方式',
            code: '10',
            describe: '人工接管遥控方式'
          }
        ],
        vehicleEventSmallTypes: [
          {
            msg: '人工接管(急停方式)',
            code: '1',
            describe: '急停'
          },
          {
            msg: '人工接管(刹车方式)',
            code: '2',
            describe: '刹车'
          },
          {
            msg: '人工接管(遥控方式)',
            code: '3',
            describe: '遥控'
          }
        ]
      },
      {
        vehicleId: 'T05',
        time: '2023-04-12 17:56:32.143',
        count: 0,
        happenTime: 1681293392143,
        type: '10',
        counts: 0,
        lat: 43.889728186195704,
        lon: 117.66340788057535,
        address: '内蒙古自治区赤峰市克什克腾旗',
        vehicleEventTypes: [
          {
            msg: '避障',
            code: '1',
            describe: '避障'
          },
          {
            msg: '变道',
            code: '2',
            describe: '变道'
          },
          {
            msg: '主动急刹',
            code: '3',
            describe: '主动急刹'
          },
          {
            msg: '被动急刹即急停',
            code: '4',
            describe: '被动急刹即急停'
          },
          {
            msg: '人工接管',
            code: '5',
            describe: '人工接管'
          },
          {
            msg: '急停',
            code: '6',
            describe: '急停'
          },
          {
            msg: '人工接管V2',
            code: '7',
            describe: '人工接管'
          },
          {
            msg: '人工接管V2-被动急刹即急停',
            code: '8',
            describe: '被动急刹即急停'
          },
          {
            msg: '人工接管V2-人工接管刹车方式',
            code: '9',
            describe: '人工接管刹车方式'
          },
          {
            msg: '人工接管V2-人工接管遥控方式',
            code: '10',
            describe: '人工接管遥控方式'
          }
        ],
        vehicleEventSmallTypes: [
          {
            msg: '人工接管(急停方式)',
            code: '1',
            describe: '急停'
          },
          {
            msg: '人工接管(刹车方式)',
            code: '2',
            describe: '刹车'
          },
          {
            msg: '人工接管(遥控方式)',
            code: '3',
            describe: '遥控'
          }
        ]
      },
      {
        vehicleId: 'T05',
        time: '2023-04-12 17:21:54.042',
        count: 0,
        happenTime: 1681291314042,
        type: '10',
        counts: 0,
        lat: 43.88955460430693,
        lon: 117.66340071849201,
        address: '内蒙古自治区赤峰市克什克腾旗',
        vehicleEventTypes: [
          {
            msg: '避障',
            code: '1',
            describe: '避障'
          },
          {
            msg: '变道',
            code: '2',
            describe: '变道'
          },
          {
            msg: '主动急刹',
            code: '3',
            describe: '主动急刹'
          },
          {
            msg: '被动急刹即急停',
            code: '4',
            describe: '被动急刹即急停'
          },
          {
            msg: '人工接管',
            code: '5',
            describe: '人工接管'
          },
          {
            msg: '急停',
            code: '6',
            describe: '急停'
          },
          {
            msg: '人工接管V2',
            code: '7',
            describe: '人工接管'
          },
          {
            msg: '人工接管V2-被动急刹即急停',
            code: '8',
            describe: '被动急刹即急停'
          },
          {
            msg: '人工接管V2-人工接管刹车方式',
            code: '9',
            describe: '人工接管刹车方式'
          },
          {
            msg: '人工接管V2-人工接管遥控方式',
            code: '10',
            describe: '人工接管遥控方式'
          }
        ],
        vehicleEventSmallTypes: [
          {
            msg: '人工接管(急停方式)',
            code: '1',
            describe: '急停'
          },
          {
            msg: '人工接管(刹车方式)',
            code: '2',
            describe: '刹车'
          },
          {
            msg: '人工接管(遥控方式)',
            code: '3',
            describe: '遥控'
          }
        ]
      },
      {
        vehicleId: 'T05',
        time: '2023-04-12 17:21:29.236',
        count: 0,
        happenTime: 1681291289236,
        type: '10',
        counts: 0,
        lat: 43.889561897971205,
        lon: 117.66340092186152,
        address: '内蒙古自治区赤峰市克什克腾旗',
        vehicleEventTypes: [
          {
            msg: '避障',
            code: '1',
            describe: '避障'
          },
          {
            msg: '变道',
            code: '2',
            describe: '变道'
          },
          {
            msg: '主动急刹',
            code: '3',
            describe: '主动急刹'
          },
          {
            msg: '被动急刹即急停',
            code: '4',
            describe: '被动急刹即急停'
          },
          {
            msg: '人工接管',
            code: '5',
            describe: '人工接管'
          },
          {
            msg: '急停',
            code: '6',
            describe: '急停'
          },
          {
            msg: '人工接管V2',
            code: '7',
            describe: '人工接管'
          },
          {
            msg: '人工接管V2-被动急刹即急停',
            code: '8',
            describe: '被动急刹即急停'
          },
          {
            msg: '人工接管V2-人工接管刹车方式',
            code: '9',
            describe: '人工接管刹车方式'
          },
          {
            msg: '人工接管V2-人工接管遥控方式',
            code: '10',
            describe: '人工接管遥控方式'
          }
        ],
        vehicleEventSmallTypes: [
          {
            msg: '人工接管(急停方式)',
            code: '1',
            describe: '急停'
          },
          {
            msg: '人工接管(刹车方式)',
            code: '2',
            describe: '刹车'
          },
          {
            msg: '人工接管(遥控方式)',
            code: '3',
            describe: '遥控'
          }
        ]
      }
    ]
  }
}
